import { useState, createContext } from "react";

type MasterContext = {
  auth?: any;
  setAuth?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [auth, setAuth] = useState(null);


  return (
    <MasterContext.Provider
      value={{
        auth,
        setAuth,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
