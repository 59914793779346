import { request, parseErrorResponse } from "./request";

const getAdminWallets = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/wallet`)
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateAdminWallets = (data) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/wallet`, data)
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AdminWalletService = {
  getAdminWallets,
  updateAdminWallets,
};

export default AdminWalletService;
