import { request, parseErrorResponse } from "./request";

const getMe = () =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/me")
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AccountService = {
  getMe,
};

export default AccountService;
