export const getUrlImage = (img) => {
  return `${
    process.env.REACT_APP_IMG || "http://localhost:3001/uploads"
  }/${img}`;
};

export function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

export const formatNumber = (number) => {
  return new Intl.NumberFormat([], { minimumFractionDigits: 2 }).format(number);
};

export const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

export const formatLocalTime = (data) => {
  return new Date(data).toLocaleString();
};

export const showToast = (toast, type = "success", messages = "") => {
  let summary = "Successful";

  switch (type) {
    case "success":
      summary = "Success Message";
      break;
    case "info":
      summary = "Info Message";
      break;
    case "warn":
      summary = "Warn Message";
      break;
    case "error":
      summary = "Error Message";
      break;
    default:
      break;
  }

  toast.current.show({
    severity: type,
    summary: summary,
    detail: messages,
    life: 2000,
  });
};

export const checkIncludeString = (contain, review) => {
  return contain.toLowerCase().includes(review.toLowerCase());
};

export const compareDate = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime();
};

export const getUrlHash = (hash) => {
  return `${
    process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
  }/tx/${hash}`;
};

export const getFromTo = (data) => {
  return `${(data || "").slice(0, 5)}...${data.slice(
    data.length - 3,
    data.length
  )}`;
};

export const getAddress = (address = "") =>
  `${address.slice(0, 7)}......${address.slice(
    address.length - 7,
    address.length
  )}`;

export const getUrlScan = (symbol: string) => {
  switch (symbol) {
    case "BNB":
    case "USDT":
    case "TSI":
      return process.env.REACT_APP_BSC_SCAN;

    case "ETH":
      return process.env.REACT_APP_ETH_SCAN;

    case "TRX":
      return process.env.REACT_APP_TRX_SCAN;

    case "BTC":
      return process.env.REACT_APP_BTC_SCAN;

    default:
      return "";
  }
};

export const BSC = {
  MAIN_NET: {
    ENPOINT: "https://bsc-dataseed.binance.org",
    SCAN: "https://api.bscscan.com/api",
    API_KEY: "4XXRFNK5N4AVF1X4K7V6D3TEPXU9CTU8KG",
    USDT_CONTRACT_ADDR: "0x55d398326f99059fF775485246999027B3197955",
    TOSI_CONTRACT_ADDR: "0x5d9111F4A9978467c4a531864F9c03a9189Dffbc",
  },
  TEST_NET: {
    ENPOINT: "https://data-seed-prebsc-1-s1.binance.org:8545",
    SCAN: "https://api-testnet.bscscan.com/api",
    API_KEY: "4XXRFNK5N4AVF1X4K7V6D3TEPXU9CTU8KG",
    USDT_CONTRACT_ADDR: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
    TOSI_CONTRACT_ADDR: "0x5d9111F4A9978467c4a531864F9c03a9189Dffbc",
  },
};

export const ETH = {
  MAIN_NET: {
    ENPOINT: "https://mainnet.infura.io/v3/214d51e99fea4e3abbd9c4f3e7f98faf",
    SCAN: "https://api.etherscan.io/api",
    API_KEY: "RSZB9I9Z37GYR9JG8UAI9F9R655HW5XHMG",
    NETWORK: "mainnet",
  },
  TEST_NET: {
    ENPOINT: "https://goerli.infura.io/v3/460e6ad5cd5e43ff9948b720015db5db",
    SCAN: "https://api-goerli.etherscan.io/api",
    API_KEY: "RSZB9I9Z37GYR9JG8UAI9F9R655HW5XHMG",
    NETWORK: "goerli",
  },
};

export const TRX = {
  MAIN_NET: {
    ENPOINT: "https://api.trongrid.io",
    SCAN: "https://apilist.tronscan.org/api",
  },
  TEST_NET: {
    ENPOINT: "https://api.shasta.trongrid.io",
    SCAN: "https://shastapi.tronscan.org/api",
  },
};
