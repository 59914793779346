import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button, Tag } from "primereact";
import TransactionService from "services/transactions";
import { renderAmount } from "utils/render";
import { formatLocalTime, getUrlScan } from "utils/common";
import { Link } from "react-router-dom";

const View = () => {
  const [data, setData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    page: 0,
    limit: 20,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);

  useEffect(() => {
    TransactionService.getTransactions({
      query: {
        ...lazyParams,
        page: lazyParams.page + 1,
      },
    })
      .then((res: any) => {
        setData(res.data);
        setTotalRecords(res.total);
      });
  }, [lazyParams]);

  // Table event
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const expandAll = () => {
    let _expandedRows = {};
    data.forEach((p) => (_expandedRows[`${p.id}`] = true));
    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const statusBodyTemplate = (rowData) => {
    return <Tag value={getStatusText(rowData)} severity={getStyleStatus(rowData)}></Tag>;
  };

  const renderTransactiontype = (rowData) => {
    const text = rowData.type === 1 ? 'Send' : (rowData.type === 2 ? 'Receive' : 'Swap')
    return <p>{text}</p>;
  };

  const renderAmountTemplate = (rowData) => {
    return renderAmount(rowData.amount)
  };

  const getStyleStatus = (item) => {
    switch (item.status) {
      case 1:
        return 'warning';

      case 2:
        return 'info';

      case 3:
        return 'success';

      case 4:
        return 'danger';

      default:
        return null;
    }
  };

  const getStatusText = (item) => {
    switch (item.status) {
      case 1:
        return 'Pending';

      case 2:
        return 'Processing';

      case 3:
        return 'Success';

      case 4:
        return 'Error';

      default:
        return null;
    }
  };

  const dateTimeTemplate = (rowData) => {
    return formatLocalTime(rowData.createdAt * 1000)
  }

  const renderTransactionHash = (rowData) => {
    const SCAN_URL = getUrlScan(rowData.coin?.symbol)
    return <Link to={{ pathname: `${SCAN_URL}/tx/${rowData.txHash}` }} target="_blank">{rowData.txHash}</Link>;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <h5>Sub-Transactions</h5>
        <DataTable
          value={data.subTransactions}
          size="small"
          scrollDirection="both"
          className="datatable-responsive"
          scrollable
          lazy
          showGridlines
        >
          <Column field="id" header="ID" style={{ flexGrow: 0, flexBasis: "6rem" }} />
          <Column field="from" header=" From" style={{ flexGrow: 1, flexBasis: "30rem" }} />
          <Column field="to" header="To" style={{ flexGrow: 1, flexBasis: "30rem" }} />
          <Column field="createdAt" header="Created At" body={dateTimeTemplate} style={{ flexGrow: 1, flexBasis: "15rem" }} />
          <Column field="status" header="Status " body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: "10rem" }} />
          <Column field="amount" header=" Amount" style={{ flexGrow: 1, flexBasis: "10rem" }} />
          <Column field="fee" header="Fee" style={{ flexGrow: 1, flexBasis: "10rem" }} />
          <Column field="description" header="Description" style={{ flexGrow: 1, flexBasis: "20rem" }} />
          <Column field="blockNumber" header="Block Number" style={{ flexGrow: 1, flexBasis: "10rem" }} />
          <Column field="txHash" header="Transaction hash" body={renderTransactionHash} style={{ flexGrow: 1, flexBasis: "50rem" }} />
        </DataTable>
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap justify-content-end gap-2 mb-3">
      <Button icon="pi pi-plus" label="Expand All" onClick={expandAll} />
      <Button icon="pi pi-minus" label="Collapse All" onClick={collapseAll} />
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            value={data}
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            // 
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            header={header}
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.limit}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
          >
            <Column expander={true} style={{ width: '4rem' }} />
            <Column field="id" header="ID" style={{ flexGrow: 0, flexBasis: "6rem" }} />
            <Column field="from" header="From" style={{ flexGrow: 1, flexBasis: "30rem" }} />
            <Column field="to" header="To" style={{ flexGrow: 1, flexBasis: "30rem" }} />
            <Column field="type" header="Type" body={renderTransactiontype} style={{ flexGrow: 1, flexBasis: "7rem" }} />
            <Column field="createdAt" header="Created At" body={dateTimeTemplate} style={{ flexGrow: 1, flexBasis: "15rem" }} />
            <Column field="status" header="Status" body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: "10rem" }} />
            <Column field="amount" header="Amount" body={renderAmountTemplate} style={{ flexGrow: 1, flexBasis: "10rem" }} />
            <Column field="fee" header="Fee" style={{ flexGrow: 1, flexBasis: "10rem" }} />
            <Column field="coin.symbol" header="Coin" style={{ flexGrow: 1, flexBasis: "10rem" }} />
            <Column field="blockNumber" header="Block Number" style={{ flexGrow: 1, flexBasis: "10rem" }} />
            <Column field="txHash" header="Transaction hash" body={renderTransactionHash} style={{ flexGrow: 1, flexBasis: "50rem" }} />
          </DataTable>
        </div>
      </div>
    </div>

  );
};

const comparisonFn = function (prevProps: { location: { pathname: any; }; }, nextProps: { location: { pathname: any; }; }) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
