import { MasterContext } from "contexts/MasterContext";
import React, { useContext } from "react";
import { formatLocalTime } from "utils/common";

const Dashboard = (props: any) => {
  const { auth } = useContext(MasterContext);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="flex flex-column sm:flex-row align-items-center gap-4">
          <div className="flex flex-column sm:flex-row align-items-center gap-3">
          <img alt="avatar" src="https://apollo.primereact.org/demo/images/avatar/circle/avatar-f-1.png" className="w-4rem h-4rem flex-shrink-0" />
          <div className="flex flex-column align-items-center sm:align-items-start">
            <span className="text-900 font-bold text-4xl">Welcome Admin!</span>
            <p className="text-600 m-0">Your last login was on {formatLocalTime(auth?.lastLoginAt*1000)}</p>
            </div>
          </div>

          <div className="flex gap-2 sm:ml-auto">
            <button className="p-button p-component p-button-icon-only p-button-outlined p-button-rounded" type="button" data-pc-name="button" data-pc-section="root">
              <span className="p-button-icon p-c pi pi-arrows-h" data-pc-section="icon"></span>
              <span className="p-button-label p-c" data-pc-section="label">&nbsp;</span>
              <span role="presentation" aria-hidden="true" className="p-ink" data-pc-name="ripple" data-pc-section="root" style={{height: '42px', width: '42px'}}></span>
            </button>
            <button className="p-button p-component p-button-icon-only p-button-outlined p-button-rounded" type="button" data-pc-name="button" data-pc-section="root">
              <span className="p-button-icon p-c pi pi-download" data-pc-section="icon"></span>
              <span className="p-button-label p-c" data-pc-section="label">&nbsp;</span>
              <span role="presentation" aria-hidden="true" className="p-ink" data-pc-name="ripple" data-pc-section="root" style={{height: '42px', width: '42px'}}></span>
            </button>
            <button className="p-button p-component p-button-icon-only p-button-rounded" type="button" data-pc-name="button" data-pc-section="root">
              <span className="p-button-icon p-c pi pi-send" data-pc-section="icon"></span>
              <span className="p-button-label p-c" data-pc-section="label">&nbsp;</span>
              <span role="presentation" aria-hidden="true" className="p-ink" data-pc-name="ripple" data-pc-section="root" style={{height: '42px', width: '42px'}}></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Dashboard;
