import { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import { BSC, ETH, TRX, showToast } from "utils/common";
import AdminWalletService from "services/wallets";
import axios from "axios";
import qs from "query-string";

const AdminWallet = () => {
  const toast = useRef(null);
  const [adminWalletResponse, setAdminWalletResponse] = useState(null);
  const [adminWallets, setAdminWallets] = useState({
    "BSC": {
      address: "",
      privateKey: "",
      networkId: 1
    },
    "BTC": {
      address: "",
      privateKey: "",
      networkId: 2
    },
    "ETH": {
      address: "",
      privateKey: "",
      networkId: 3
    },
    "TRX": {
      address: "",
      privateKey: "",
      networkId: 4
    }
  });
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState({
    BNB: null,
    USDT: null,
    TSI: null,
    ETH: null,
    TRX: null,
    BTC: null,
  });

  useEffect(() => {
    getData();
  }, []);

  const submitHandler = async (e, network, data: { [key: string]: { address: string, networkId: number, privateKey: string } }) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!data[network].address || !data[network].networkId || !data[network].privateKey) {
        showToast(toast, "error", "Invalid data");
        setLoading(false);
        return;
      }

      await AdminWalletService.updateAdminWallets(data);
      await getData();
      showToast(toast, "success", "Update success!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const msg = error?.message || "Something went wrong!";
      showToast(toast, "error", msg);
    }
  };

  const getData = async () => {
    try {
      const res: any = await AdminWalletService.getAdminWallets();
      if (res) {
        setAdminWalletResponse(res);
        const data = {
          "BSC": {
            ...adminWallets.BSC,
            address: res.BSC?.address,
            privateKey: ""
          },
          "BTC": {
            ...adminWallets.BTC,
            address: res.BTC?.address,
            privateKey: ""
          },
          "ETH": {
            ...adminWallets.ETH,
            address: res.ETH?.address,
            privateKey: ""
          },
          "TRX": {
            ...adminWallets.TRX,
            address: res.TRX?.address,
            privateKey: ""
          }
        }
        setAdminWallets(data)
      }
    } catch (error) { }
  };

  const updateValue = (fieldName, network, value) => {
    const data = {
      ...adminWallets,
      [network]: {
        ...adminWallets[network],
        [fieldName]: value
      },
    }
    setAdminWallets(data)
  }

  const getBalance = async () => {
    let _balance = { }
    if (adminWalletResponse && adminWalletResponse.BSC?.address) {
      const query = {
        module: "account",
        action: "balance",
        address: adminWalletResponse.BSC.address,
        tag: "latest",
        apikey: BSC.TEST_NET.API_KEY
      }
      // Get BNB Balance
      const params_bnb = qs.stringify(query);
      const res_bnb = await axios.get(`${BSC.TEST_NET.SCAN}?${params_bnb}`);
      _balance['BNB'] = (res_bnb && res_bnb.data.status === '1') ? Number(res_bnb.data.result) / 10 ** 18 : null;

      // Get USDT Balance
      query['action'] = 'tokenbalance'
      query['contractaddress'] = BSC.TEST_NET.USDT_CONTRACT_ADDR;
      const params_usdt = qs.stringify(query);
      const res_usdt = await axios.get(`${BSC.TEST_NET.SCAN}?${params_usdt}`);
      _balance['USDT'] = (res_usdt && res_usdt.data.status === '1') ? Number(res_usdt.data.result) / 10 ** 18 : null;

      // Get TSI Balance
      query['contractaddress'] = BSC.TEST_NET.TOSI_CONTRACT_ADDR;
      const params_tsi = qs.stringify(query);
      const res_tsi = await axios.get(`${BSC.TEST_NET.SCAN}?${params_tsi}`);
      _balance['TSI'] = (res_tsi && res_tsi.data.status === '1') ? Number(res_tsi.data.result) / 10 ** 18 : null;

    }

    if (adminWalletResponse && adminWalletResponse.ETH?.address) {
      const query = {
        module: "account",
        action: "balance",
        address: adminWalletResponse.ETH.address,
        tag: "latest",
        apikey: ETH.TEST_NET.API_KEY
      }
      const params_eth = qs.stringify(query);
      const res_eth = await axios.get(`${ETH.TEST_NET.SCAN}?${params_eth}`);

      _balance['ETH'] = (res_eth && res_eth.data.status === '1') ? Number(res_eth.data.result) / 10 ** 18 : null;
    }

    if (adminWalletResponse && adminWalletResponse.TRX?.address) {
      const query = {
        address: adminWalletResponse.TRX.address,
      }
      const params = qs.stringify(query);
      const res_trx = await axios.get(`${TRX.TEST_NET.SCAN}/account?${params}`);
      _balance['TRX'] = (res_trx && res_trx.status === 200) ? Number(res_trx.data.balance) / 10 ** 6 : null;
    }

    setBalance({...balance, ..._balance})
  }

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminWalletResponse])


  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="card w-full">
        <div className="row">
          <div className="col-12">
            Binace Smart Chain
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputText
              required
              value={adminWallets.BSC.address}
              className="w-full"
              onChange={(e) => updateValue("address", "BSC", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputText
              required
              placeholder="Private key"
              value={adminWallets.BSC.privateKey}
              className="w-full"
              onChange={(e) => updateValue("privateKey", "BSC", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="text-bold">Current Balance:</p>
            <p>BNB: {balance.BNB}</p>
            <p>USDT: {balance.USDT}</p>
            <p>TSI: {balance.TSI}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-2">
              <Button label="Submit" loading={loading} onClick={(e) => { submitHandler(e, 'BSC', { BSC: adminWallets.BSC }) }} />
            </div>
          </div>
        </div>

      </div>

      <div className="card w-full">
        <div className="row">
          <div className="col-12">
            Ethereum
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputText
              required
              value={adminWallets.ETH.address}
              className="w-full"
              onChange={(e) => updateValue("address", "ETH", e.target.value)}
            />
          </div>
        </div>

        <div className="row flex">
          <div className="col-12">
            <InputText
              required
              placeholder="Private key"
              value={adminWallets.ETH.privateKey}
              className="w-full"
              onChange={(e) => updateValue("privateKey", "ETH", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="text-bold">Current Balance:</p>
            <p>ETH: {balance.ETH}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-2">
              <Button label="Submit" loading={loading} onClick={(e) => submitHandler(e, 'ETH', { ETH: adminWallets.ETH })} />
            </div>
          </div>
        </div>
      </div>

      <div className="card w-full">
        <div className="row">
          <div className="col-12">
            Tron
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputText
              required
              value={adminWallets.TRX.address}
              className="w-full"
              onChange={(e) => updateValue("address", "TRX", e.target.value)}
            />
          </div>
        </div>

        <div className="row flex">
          <div className="col-12">
            <InputText
              required
              placeholder="Private key"
              className="w-full"
              value={adminWallets.TRX.privateKey}
              onChange={(e) => updateValue("privateKey", "TRX", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="text-bold">Current Balance:</p>
            <p>TRX: {balance.TRX}</p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-2">
              <Button label="Submit" loading={loading} onClick={(e) => submitHandler(e, 'TRX', { TRX: adminWallets.TRX })} />
            </div>
          </div>
        </div>
      </div>

      <div className="card w-full">
        <div className="row">
          <div className="col-12">
            Bitcoin
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <InputText
              required
              value={adminWallets.BTC.address}
              className="w-full"
              onChange={(e) => updateValue("address", "BTC", e.target.value)}
            />
          </div>
        </div>

        <div className="row flex">
          <div className="col-12">
            <InputText
              required
              placeholder="Private key"
              value={adminWallets.BTC.privateKey}
              className="w-full"
              onChange={(e) => updateValue("privateKey", "BTC", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mt-2">
              <Button label="Submit" loading={loading} onClick={(e) => submitHandler(e, 'BTC', { BTC: adminWallets.BTC })} />
            </div>
          </div>
        </div>

      </div>

      
    </div>
  );
};

export default AdminWallet;
